<template>
  <div>
    <DeleteCustomDomainV2 :deleteCustomDomain="deleteDomainObj"></DeleteCustomDomainV2>
    <table class="custom_domains_table w-full h-full text-left ">
      <thead
        :class="'bg-[#F4F6FA]'"
        class="text-[#757A8A] font-semibold text-[0.875rem] font-poppins"
      >
      <tr>
        <td class="py-3 pl-3 w-[12.5%]">Connectivity</td>
        <td class="py-3 text-left w-[20%]">URL</td>
        <td class="py-3 text-left w-[20%] pl-3">404 Redirect URL</td>
        <td class="py-3 text-left w-[12.5%] pl-3">Created</td>
        <td class="py-3 text-left w-[12.5%]">Set As Default</td>
        <td class="py-3 text-left w-[12.5%]">Use HTTPS</td>
        <td class="py-3 pr-3 text-right w-[12.5%]" v-if="checkNotClient()">
          Actions
        </td>
      </tr>
      </thead>
      <tbody v-if="getCustomDomainsLoader.custom_domain_loader">
      <tr>
        <td colspan="2">
          <div class="p-5 text-center">
            <clip-loader :size="'26px'" :color="'#1d4ed8'"></clip-loader>
          </div>
        </td>
      </tr>
      </tbody>
      <tbody v-else-if="getDomains.items.length === 0">
      <tr>
        <td colspan="7" class="">
          <p class="no-rows text-[#3C4549] text-[0.875rem] font-poppins">
            <i class="fab fa-bandcamp" aria-hidden="true"></i>

            You have not added any custom domains yet, click on the below button
            to get started!
          </p>
        </td>
      </tr>
      </tbody>
      <tbody
        v-else-if="getDomains.items.length > 0"
        class="font-poppins text-[#3C4549] text-[0.875rem]"
      >
      <tr
        v-for="(domain, index) in getDomains.items"
        class="border-b border-b-[#ECEEF5]"
      >
        <td class="py-4 pl-3 w-[12.5%]">
          <div
            v-tooltip="domainStatusMessage(domain.connectivity)"
            v-if="domain.connectivity"
          >
            <div class="flex">
              <div
                class="w-[1.42rem] h-[1.42rem] bg-success rounded-full mr-[0.42rem] text-white justify-center items-center inline-flex"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="8"
                  viewBox="0 0 10 8"
                  fill="none"
                >
                  <path
                    d="M8.75 1.18767L3.59375 6.81267L1.25 4.25585"
                    stroke="#F7F7FC"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div
            v-tooltip="domainStatusMessage(domain.connectivity)"
            v-else-if="!domain.connectivity"
          >
            <div class="flex">
              <div
                class="w-[1.42rem] h-[1.42rem] bg-[#C13A52] rounded-full mr-[0.42rem] text-white justify-center items-center inline-flex"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6 4V6"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6 8H6.005"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </td>
        <td class="py-4 pr-3 text-left  w-[20%]">
          {{ trimUrl(domain.url) }}
        </td>
        <td class="py-4 pr-3 text-left w-[20%]">
          {{ domain.redirect_url ? domain.redirect_url : "N/A" }}
        </td>
        <td class="py-4 text-left w-[12.5%]">
          {{ domain.created_at | relativeCaptilize }}
        </td>
        <td class="py-4 text-left w-[12.5%]">
          <SwitchButton
            :status="!domain.default"
            :disabled="!domain.connectivity"
            @click="setDomainHTTPSMethod(domain)"
          ></SwitchButton>
        </td>
        <td class="py-4 text-left w-[12.5%]">
          <SwitchButton
            :status="!domain.useHttps"
            :disabled="!domain.connectivity"
            @click="setDomainHTTPSMethod(domain)"
          ></SwitchButton>
        </td>
        <td class="py-4 pr-3 flex justify-end gap-1" v-if="checkNotClient()">
          <svg
            :class="
              ActiveModal === 'checkConnectivity'
                ? 'rounded-full !bg-[#F4F6FA)]'
                : ''
            "
            class="cursor-pointer mr-2"
            v-tooltip="'Check Connectivity'"
            width="14"
            height="20"
            viewBox="0 0 14 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="!domain.showLoader"
            @click="
              ActiveModal = 'checkConnectivity';
              checkConnectivity(domain);
            "
          >
            <path
              d="M0.5 5H13.5V8.5C13.5 12.0899 10.5899 15 7 15C3.41015 15 0.5 12.0899 0.5 8.5V5Z"
              stroke="#3C4549"
            />
            <path d="M4.08337 5.24996V0.291626" stroke="#3C4549"/>
            <path d="M9.91663 5.24996V0.291626" stroke="#3C4549"/>
            <path d="M7 19.7083V14.75" stroke="#3C4549"/>
          </svg>
          <clip-loader
            class="d-inline-block mr-2"
            :size="'16px'"
            :color="'#3988fe'"
            style="vertical-align: middle"
            v-tooltip="'Checking Connectivity...'"
            v-if="domain.showLoader"
          ></clip-loader>

          <svg
            :class="
              ActiveModal === 'showDNSRecords'
                ? 'rounded-full !bg-[#F4F6FA)]'
                : ''
            "
            width="18"
            v-tooltip="'Show DNS Records'"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="far fa-globe cursor-pointer mr-2"
            @click.prevent="
              ActiveModal = 'showDNSRecords';
              showDNSRecords(domain.url);
            "
          >
            <path
              d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
              stroke="#3C4549"
              stroke-width="0.8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1 9H17"
              stroke="#3C4549"
              stroke-width="0.8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.99993 1C11.001 3.19068 12.1381 6.03363 12.1999 9C12.1381 11.9664 11.001 14.8093 8.99993 17C6.9989 14.8093 5.86172 11.9664 5.79993 9C5.86172 6.03363 6.9989 3.19068 8.99993 1Z"
              stroke="#3C4549"
              stroke-width="0.8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <svg
            :class="
              ActiveModal === 'editDomain' ? 'rounded-full !bg-[#F4F6FA)]' : ''
            "
            class="cursor-pointer mr-2"
            v-tooltip="'Edit Domain'"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click.prevent="
              ActiveModal = 'editDomain';
              editDomain(domain);
            "
          >
            <path
              d="M11.6533 1.15998C12.0759 0.737402 12.6491 0.5 13.2467 0.5C13.8443 0.5 14.4174 0.737402 14.84 1.15998C15.2626 1.58256 15.5 2.1557 15.5 2.75332C15.5 3.35093 15.2626 3.92407 14.84 4.34665L4.74889 14.4378L0.5 15.5L1.56222 11.2511L11.6533 1.15998Z"
              stroke="#3C4549"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <svg
            :class="
              ActiveModal === 'deleteCustomDomain'
                ? 'rounded-full !bg-[#F4F6FA)]'
                : ''
            "
            class="cursor-pointer"
            v-tooltip="'Delete Custom Domain'"
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click.prevent="deleteDomainObj = domain ;$bvModal.show('delete_custom_domain')"

          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.70143 1.91636C5.88803 1.72976 6.14112 1.62493 6.40501 1.62493H9.59505C9.85894 1.62493 10.112 1.72976 10.2986 1.91636C10.4852 2.10296 10.5901 2.35605 10.5901 2.61994V3.61398H5.41V2.61994C5.41 2.35605 5.51483 2.10296 5.70143 1.91636ZM4.77554 4.81398C4.78694 4.81463 4.79843 4.81496 4.81 4.81496C4.82157 4.81496 4.83306 4.81463 4.84446 4.81398H11.1556C11.167 4.81463 11.1785 4.81496 11.1901 4.81496C11.2016 4.81496 11.2131 4.81463 11.2245 4.81398H12.9826V15.3801C12.9826 15.644 12.8778 15.897 12.6912 16.0836C12.5045 16.2703 12.2515 16.3751 11.9876 16.3751H4.01249C3.7486 16.3751 3.49551 16.2703 3.30891 16.0836C3.12231 15.897 3.01748 15.644 3.01748 15.3801V4.81398H4.77554ZM4.21 3.61398V2.61994C4.21 2.03779 4.44126 1.47948 4.8529 1.06783C5.26455 0.656187 5.82286 0.424927 6.40501 0.424927H9.59505C10.1772 0.424927 10.7355 0.656187 11.1472 1.06783C11.5588 1.47948 11.7901 2.03779 11.7901 2.61994V3.61398H15.1776C15.5089 3.61398 15.7776 3.88261 15.7776 4.21398C15.7776 4.54536 15.5089 4.81398 15.1776 4.81398H14.1826V15.3801C14.1826 15.9622 13.9513 16.5205 13.5397 16.9322C13.128 17.3438 12.5697 17.5751 11.9876 17.5751H4.01249C3.43034 17.5751 2.87203 17.3438 2.46038 16.9322C2.04874 16.5205 1.81748 15.9622 1.81748 15.3801V4.81398H0.822412C0.491041 4.81398 0.222412 4.54536 0.222412 4.21398C0.222412 3.88261 0.491041 3.61398 0.822412 3.61398H2.41743H4.21ZM6.40487 7.60191C6.73624 7.60191 7.00487 7.87054 7.00487 8.20191V12.987C7.00487 13.3183 6.73624 13.587 6.40487 13.587C6.0735 13.587 5.80487 13.3183 5.80487 12.987V8.20191C5.80487 7.87054 6.0735 7.60191 6.40487 7.60191ZM10.195 8.20191C10.195 7.87054 9.92637 7.60191 9.595 7.60191C9.26363 7.60191 8.995 7.87054 8.995 8.20191V12.987C8.995 13.3183 9.26363 13.587 9.595 13.587C9.92637 13.587 10.195 13.3183 10.195 12.987V8.20191Z"
              fill="#97304A"
            />
          </svg>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {domainTypes} from "@/state/modules/mutation-types";
import eventBus from "./js/event-bus.js";
import DeleteCustomDomainV2 from "@/views/pages/setting/domains/dialogs/DeleteCustomDomainV2.vue";
import * as domain from "domain"; // Import the event bus

export default {
  components: {
    DeleteCustomDomainV2,
    SwitchButton: () => import("@/ui/ui-kit/v2/SwitchButton.vue"),
  },
  data() {
    return {
      ActiveModal: "",
      isCheckDomainConnectivity: false,
      isShowModal: false,
      deleteDomainObj: {}
    };
  },
  async created() {
    await this.fetchDomains();
    eventBus.$on("updateCloseModalEvent", () => {
      this.ActiveModal = "";
    });
  },
  methods: {
    ...mapActions([
      "checkDomainConnectivity",
      "deleteDomain",
      "checkDNSRecordsForDomain",
      "setDomainHTTPSMethod",
      "setDefaultDomainStatus",
      "fetchDomains",
      "fetchCustomDomainLink",
    ]),
    ...mapMutations([domainTypes.SET_ADD_DOMAIN]),
    domainStatusMessage(status) {
      if (status) {
        return "Your domain is verified and connected with Replug.io";
      }
      return "Your domain is not verified yet";
    },
    domainSSLMessage(status) {
      if (status) {
        return "Your domain is ssl verified and connected with Replug.io";
      }
      return "Your domain is not ssl verified yet";
    },
    editDomain(domain) {
      // this.isShowModal = true
      // this[domainTypes.SET_ADD_DOMAIN](domain)
      // $('#customDomainModal').modal('show')
      this.$parent.showModal(true, domain);
      // setTimeout(() => {
      //   this.$bvModal.show('customDomainModal')
      // },100)
    },
    closeModal() {
      this.isShowModal = false;
      setTimeout(() => {
        this.isShowModal = true;
      }, 100);
    },
    async showDNSRecords(url) {
      this.checkDNSRecordsForDomain(url);
      this.$bvModal.show("dns_records_modal");
    },
    async checkConnectivity(domain) {
      domain.showLoader = true;
      await this.checkDomainConnectivity(domain);
      domain.showLoader = false;
      this.ActiveModal = "";
    },
    deleteCustomDomain(domain) {
      this.fetchCustomDomainLink(domain);
      this.ActiveModal = "";
    },
  },
  computed: {
    domain() {
      return domain
    },
    ...mapGetters(["getDomains", "getCustomDomainsLoader"]),
  },
};
</script>
<style lang="less">
.custom_domains_table {
  tbody {
    display: block;
    overflow-y: auto;
    height: calc(100vh - 14rem);
    table-layout: fixed;
  }

  thead, thead tr, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}
</style>
